import { useState, useEffect, useCallback } from 'react';
import logo from './logo.svg';
import './App.css';
import 'react-big-calendar/lib/css/react-big-calendar.css';


import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";


import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { DateRangePicker, SingleDatePicker, DayPickerRangeController } from 'react-dates';


import { Container, Row, Col, Input } from 'reactstrap';

import 'bootstrap/dist/css/bootstrap.min.css';

const localizer = momentLocalizer(moment);

let state = {
  events: [
    {
      start: moment().toDate(),
      end: moment()
        .add(1, "days")
        .toDate(),
      title: "[2D] Peck"
    }
  ]
};

function App() {


  const [startDate, setStartDate] = useState(moment());
  const [endDate, setEndDate] = useState(moment().add(1, 'days'));
  const [focusedInput, setFocusedInput] = useState(null);


  const [people, setPeople] = useState(1);


  const [extraPeopleCost, setExtraPeopleCost] = useState(null);
  const [totalDays, setTotalDays] = useState(null);
  const [accessoryCost, setAccessoryCost] = useState(null);
  const [taxCost, setTaxCost] = useState(null);
  const [totalCost, setTotalCost] = useState(null);
  useEffect(() => {
    if (!startDate || !endDate) return
    let days = endDate.diff(startDate, 'days')
    setTotalDays(days)


    let touristTax = days * people * 1.50
    let extraPeople = people - 4
    extraPeople = Math.max(extraPeople, 0)

    let newExtraPeopleCost = extraPeople * days * 5
    setExtraPeopleCost(newExtraPeopleCost)

    let apartmentCost = days * 50
    let cleaningCost = 45

    let newTotalCost = apartmentCost + extraPeople + touristTax + cleaningCost + newExtraPeopleCost

    setAccessoryCost(5)
    setTaxCost(touristTax)
    setTotalCost(newTotalCost)
  }, [startDate, endDate, people])

  return (
    <Container>
      {/* <Calendar
        localizer={localizer}
        defaultDate={new Date()}
        defaultView="month"
        events={state.events}
        style={{ height: "100vh" }}
      /> */}

      <Row>
        <Col>

          <Input value={people} onChange={(e) => setPeople(e.target.value)} />

          <DateRangePicker
            startDate={startDate} // momentPropTypes.momentObj or null,
            startDateId="your_unique_start_date_id" // PropTypes.string.isRequired,
            endDate={endDate} // momentPropTypes.momentObj or null,
            endDateId="your_unique_end_date_id" // PropTypes.string.isRequired,
            onDatesChange={
              (e) => {
                console.log(e)
                setStartDate(e.startDate)
                setEndDate(e.endDate)
              }
            }
            focusedInput={focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
            onFocusChange={e => setFocusedInput(e)} // PropTypes.func.isRequired,
          />
        </Col>
        <br />
        <small>{totalDays} Days</small>
        {
          extraPeopleCost > 0 && <small>Extra people &euro;{extraPeopleCost} </small>
        }
        <small>Tax &euro;{taxCost}</small>
        <h4>Per person: &euro;{(totalCost / people).toFixed(2).toLocaleString()}</h4>
        <h4>Total: &euro;{totalCost}</h4>
      </Row>
    </Container>
  );
}

export default App;
